.landing_02-main-container {
    width: 100%;
    padding-left: 7rem;
    padding-right: 7rem;
}

.landing_02-projects-container {
    width: 100%;
    margin-top: 1vw;
    margin-bottom: 2vw;
}

.title-landing_02 {
    padding-left: 7rem;
    font-size: 0.8vw;
}

.landing_02-project-card {
    position: relative; /* Needed for tooltip positioning */
    width: 100%;
    border-bottom: 1px solid grey;
    padding-left: 7rem;
    padding-right: 7rem;
    padding-top: 3vw;
    padding-bottom: 3vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.landing_02-project-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.tooltip-container {
    position: fixed; /* Keeps tooltip relative to viewport */
    top: 0;
    left: 0;
    width: 30vw; /* Width of the tooltip */
    height: 30vw; /* Height of the tooltip */
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none; /* Allows clicks to pass through */
    z-index: 900; /* Ensures tooltip is above other elements */
    overflow: hidden; /* Ensure content doesn’t overflow the circle */
}


.tooltip-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the circle */

}

.landing_02-project-card:first-child {
    border-top: 1px solid grey;
}

.landing_02-project-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.landing_02-project-info h2 {
    font-size: 3vw;
}

.landing_02-morework-button {
    border: 1px solid var(--primary-color);
    border-radius: 1vw;
    padding: 2vw;
    margin-bottom: 3rem;
}
.landing_02-morework-button:hover{
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--secondary-color);
    

}

.project-image {
    display: none;
}

@media (max-width: 1100px) {
    .landing_02-main-container {
        padding: 2rem;
    }
    .landing_02-project-card:first-child {
        border-top: none;
    }
    .title-landing_02 {
        display: none;
        padding-left: 0rem;
        font-size: 0.8vw;
    }
    .landing_02-project-card {
        padding: 0px;
        margin-bottom: 3rem;
    }
    .landing_02-project-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .project-image {
        display: block;
        padding-bottom: 1rem;
        width: 100%;
    }
    .tooltip-container{
        display: none;
    }
}
