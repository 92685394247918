.aboutMainContainer {
   opacity: 0;
   transition: opacity 3s;
 }
 
 @media (min-width: 400px) {
   .containerFirst {
     width: 100%;
     padding: 13vw;
     padding-top: 14vw;
   }
 
   .firstTitle {
     padding: 0;
     margin: 0;
     font-size: 7vw;
     line-height: 8vw;
   }
 
   .line {
     margin-top: -3rem;
     border-bottom: 1px solid grey;
     padding-top: -50%;
   }
 
   .circleOverline {
     position: relative;
     bottom: -75px;
     left: 75%;
     min-height: 150px;
     min-width: 150px;
     max-height: 130px;
     max-width: 130px;
     background-color: rgb(61, 61, 231);
     border-radius: 100%;
   }
 
   .containerSecond {
     padding: 13vw;
     padding-top: 3vw;
     display: flex;
     flex-direction: row;
     width: 100%;
   }
 
   .containerTextfirstAbout {
     width: 30%;
     padding-right: 3rem;
   }
 
   .containerImageAbout {
     width: 70%;
   }
 
   .secondtextShort {
     padding-top: 3rem;
   }
 
   .containerThird {
     width: 100%;
     padding: 13vw;
     padding-top: 2vw;
   }
 
   .thirdTitle {
     font-size: 3.8vw;
   }
 
   .containerOptions {
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     margin-top: 3vw;
   }
 
   .boxesAbout {
     width: 30%;
     border-top: 1px solid gray;
     padding-top: 2rem;
   }
 
   .boxesAbout h2 {
     font-size: 2.4vw;
     padding-bottom: 2rem;
   }
 }
 
 /* Mobile */
 
 @media (max-width: 800px) {
   .containerFirst {
     width: 100%;
     padding: 13vw;
     padding-top: 14vw;
   }
 
   .firstTitle {
     padding: 0;
     margin: 0;
     font-size: 7vw;
     line-height: 8vw;
   }
 
   .circleOverline {
     left: 65%;
   }
 
   .containerDoubleText {
     margin-top: 2rem;
   }
 
   .containerSecond {
     padding: 13vw;
     padding-top: 3vw;
     display: flex;
     flex-direction: column;
     width: 100%;
   }
 
   .containerThird {
     padding: 2rem;
   }
 
   .containerTextfirstAbout {
     width: 100%;
     padding: 0px;
     padding-bottom: 3rem;
   }
 
   .containerImageAbout {
     width: 100%;
   }
 
   .secondtextShort {
     padding-top: 3rem;
   }
 
   .thirdTitle {
     font-size: 6.3vw;
   }
 
   .containerOptions {
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     margin-top: 3vw;
   }
 
   .boxesAbout {
     width: 100%;
     border-top: 1px solid gray;
     padding-top: 2rem;
     margin-top: 3rem;
   }
 
   .boxesAbout h2 {
     font-size: 4.4vw;
     padding-bottom: 2rem;
   }
 }
 