.navContainerLanding {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem;
  width: calc(100vw - 4rem);
  color: var(--secondary-color);
}

.hambuContainer {
  display: flex;
  flex-direction: row;
}

.hambuContainer .landingHola {
  margin-left: 2rem;
}
.landingHola:hover{
  color: var(--secondary-color-half-opacity);
}
.homeLink:hover{
  color: var(--secondary-color-half-opacity);
}

.hambuContainerMobile {
  display: none;
}

.sideNav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  z-index: 9999;
  overflow: hidden;
  transform: translateX(100%); /* Start off-screen */
}

.linksPages {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}
.socialMedia {

  width: calc(100vw - 4rem);
  margin-top: 6rem;
}


.socialMediaLinks {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.descriptionContent {
  margin-bottom: 3rem;
}

.descriptionContent p {
  font-size: 12px;
  color: grey;
}

.hrClass {
  margin-top: 0.8rem;
  border-top: 1px solid grey;
}

.socialMediaLinks .socialLink {
  margin-right: 2rem;
}

.sideNavLink {
  margin-bottom: 0.8rem;
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 38px;
}

@media (max-width: 540px) {
  .hambuContainer {
    display: none;
  }
  .hambuContainerMobile {
    display: block;
  }
}

.closeBtn {
  position: absolute;
  right: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background-color: var(--tertiary-color);
  color: var(--secondary-color);
}

.activeLink {
  text-decoration: underline;
  text-underline-offset: 8px; /* Adjust this value to create more space */
}
