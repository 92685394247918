
  .footer-container{
    width: 100%;
    background-color: rgb(0, 0, 0);
    padding: 2rem;
   }
  .footer-first-container{
    padding: 8rem;
   }
   .headshot-fulltitle-container{
      margin-top: 6rem;
   }
   .headshot-title-container{
      display: flex;
      flex-direction: row;
      align-items: center;
   }
   .footer-headshot{
    height: 5.6vw;
    width: 5.6vw;
    border-radius: 100% ;
    margin-right: 0.5rem;

   }
   .footer-title{
      font-size: 6.4vw;
      color: white;
      line-height: 6vw;

   }
   .line{
    margin-top: -3rem;
    border-bottom: 1px solid grey;
    padding-top: -50%;
   }
   .circle-overline{
    position: relative;
    bottom: -75px;
    left: 75%;
    min-height: 150px;
    min-width: 150px;
    max-height: 130px;
    max-width: 130px;
    background-color:var(--tertiary-color);
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
   }
   .circle-overline:hover{
      background-color:var(--tertiary-color-half-opacity);

   }
   .buttons-footer-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
    padding-bottom: 4rem;

   }
   .buttons-footer-container button{
    border: 1px solid grey;
    border-radius: 50px;
    color: white;
    padding: 2vw;
    margin-bottom: 2rem;
    margin-right: 2rem;

   }
   .buttons-footer-container button:hover{
      border: 1px solid var(--secondary-color);
      background-color: var(--secondary-color);
      color: var(--primary-color);
      
  
  }
   .footer-bottom-info-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    margin-top: 2rem;
    padding: -4rem;
   }
   .footer-social-container{
    display: flex;
    flex-direction: row;
   }

   .footer-version-time-container{
    display: flex;
    flex-direction: row;
   }
   .footer-version-container{
    margin-right: 2rem;
   }

   .footer-title-end{
      color:grey;
      font-size: 12px;
   }
   .footer-social-container a{
    margin-right: 1rem;
   }
   .footer-social-links:hover{
      color: var(--secondary-color-half-opacity);

   }
   @media ( width <= 800px ){


    .footer-first-container{
      padding: 0rem;
     }
     .footer-headshot{
      height: 10.6vw;
      width: 10.6vw;

  
     }
     .footer-title{
      font-size: 9.4vw;
      line-height: 9vw;

     }
     .circle-overline{
   
      left: 63%;

     }
     .footer-bottom-info-container{
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      width: 100%;
   
     }
     .footer-version-time-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 3rem;
      padding-top: 3rem;
      border-top: 1px solid;
     }
     .buttons-footer-container{
    

        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 9rem;
        margin-right: 0rem;

    
     }
     .buttons-footer-container button{
    
      margin-right: 0rem;

     }
  
   }


