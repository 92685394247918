/* src/components/Splash/Splash.css */

.splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:var(--primary-color);
  color:var(--secondary-color);
  opacity: 0;
  z-index: 1000;
  pointer-events: none;
  overflow: hidden; 
  font-size: 5vw;
}

/* Animation class for height animation */
.splash.withdraw {
  height: 100%; /* Initial height */
  transition: height 1s ease; /* Smooth height transition */
}
