.container-landing01{
    padding: 6rem;
    padding-top: 10rem;
    padding-bottom:10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.sub-container-text{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.sub-container-btn{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.first-text{
    font-size: 3.8vh;
    width: 50%;
    padding-right: 4rem;
}

.second-text{
    padding-top: 1rem;
    width: 20%;
    font-size: 1.8vh;

}

.btn-aboutme{

    max-height: 150px;
    max-width: 150px;

    min-height: 150px;
    min-width: 150px;

    border-radius: 100%;
    background-color:var(--tertiary-color);
    color:white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.btn-aboutme:hover{
    background-color:var(--tertiary-color-half-opacity);

}

@media ( width <= 900px ){

    .container-landing01{
        padding: 2rem;
        padding-top: 10rem;
        padding-bottom:10rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .second-text-mobile{
        display: block;
        padding-right: 1rem;
    
    }
    .second-text{
       display: none;
    }
    .first-text{
        width: 100%;
        padding: 0;
        font-size: 2.8vh;

    }
 
}

@media ( width >= 701px ){

 

    .second-text-mobile{
        display: none;
    
    }
    .sub-container-btn{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 2rem;
        padding-right: 8%;
    
    }
}