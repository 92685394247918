/* General styles */

.work-main-container{
  opacity: 0;
  transition: opacity 3s; /* Optional: add a transition for a smoother effect */
}

@media (min-width: 800px) {
  .work-title-container {
    width: 100%;
    padding: 14rem;
    padding-top: 10rem;
    padding-bottom: 4rem;
  }

  .work-title-container h2 {
    font-size: 6vw;
    line-height: 7vw;
  }

  .work-filter-container {
    width: 100%;
    padding: 14rem;
    padding-left: 14rem;
    padding-right: 14rem;
    padding-bottom: 4rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .work-filters {
    display: flex;
    flex-direction: row;
  }
  .work-filters button:hover{
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--secondary-color);
    

}

  .work-filters button {
    border: 1px solid grey;
    border-radius: 50px;
    padding: 0 2.5em;
    margin-right: 1rem;
    height: 4rem;
    background: none;
    color: black;
    cursor: pointer;
  }

  .work-filters .active {
    background-color: black;
    color: white;
  }

  .work-filter-view {
    display: none;
    flex-direction: row;
  }

  .work-filter-view button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    border-radius: 50%;
    margin-right: 1rem;
    height: 4rem;
    width: 4rem;
  }

  .description-row-container {
    width: 100%;
    border-bottom: 1px solid rgb(226, 226, 226);
    padding: 7.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    font-size: 0.8vw;
  }

  .description-client-container {
    width: 40%;
  }

  .description-location-container {
    width: 25%;
  }

  .description-servi-year-container{
    
    width: 35%;
    display: flex;
    flex-direction: row;
  }
  .description-services-container {
    width: 70%;
  }


  .description-year-container {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .container-mvp {
    padding: 7.5rem;
    padding-top: 0rem;
    width: 100%;
  }

  .card {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    border-bottom: 1px solid rgb(226, 226, 226);
    cursor: pointer;
  }

  .card-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 3vw;
  }

  .projects-title {
    width: 35%;
    font-size: 3vw;
  }

  .projects-location {
    width: 20%;
    font-size: 1.3vw;
  }

  .servi-year-container {
    width: 35%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .servi-year-container .projects-services {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 1.3vw;
  }

  .servi-year-container .projects-year {
    width: 20%;
    font-size: 1.3vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .projects-title:first-child {
    font-family: 'Courier New', Courier, monospace;
  }
  .work-tooltip-container {
    position: absolute;
    z-index: 1000; /* Ensure it appears above other elements */
    width: 200px; /* Set a width */
    border: 1px solid #ccc;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .work-tooltip-image {
    width: 100%;
    height: auto;
  }
  
}


@media ( width <= 1100px ){

  .work-title-container{
    width: 100%;
    padding: 2rem;
    padding-top: 6rem;
   
  }
  
  .work-title-container h2{
    font-size: 9vw;
    line-height: 10vw;
  }

  .work-filter-container{
    width: 100%;
    padding: 2rem;
    padding-top: 0rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .work-filters{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .work-filters button{
    border: 1px solid grey;
    border-radius: 50px;
    padding: 0 1.6em;
    margin-right: 0rem;
    height: 3.6rem;
    font-size: 3.8vw;
  } 

  .work-filters .active {
    background-color: black;
    color: white;
  }

  .work-filter-view{
    display: none;
    
  }
  
  .work-filter-view button{
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    border-radius: 50%;
   
    margin-right: 1rem;
    height: 4rem;
    width: 4rem ;
  }
  .container-mvp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 2rem;
    width: 100%;
  }
  .description-row-container{
    display: none;
  }
  .card {
    border-bottom: 1px solid rgb(226, 226, 226);
    padding: 16px;
  }

  .card-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .projects-title, 
  .projects-location, 
  .projects-services {
    width: 100%;
    font-size: 2vw;
    margin-bottom: 0.5rem;
  }

  .projects-location{
    display: none;
  }
  .servi-year-container{
    width: 100%;
    display: flex;
    flex-direction: row;
   
  }
  .projects-year{
    width: 20%;
  }



}

@media ( width <= 600px ){
  .work-title-container{
    width: 100%;
    padding: 2rem;
    padding-top: 6rem;
   
  }
  
  .work-title-container h2{
    font-size: 9vw;
    line-height: 10vw;
  }
  
  .work-filter-container{
    width: 100%;
    padding: 2rem;
    padding-top: 0rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .work-filters{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .work-filters button{
    border: 1px solid grey;
    border-radius: 50px;
    padding: 0 1.6em;
    margin-right: 0rem;
    height: 3.6rem;
    font-size: 3.8vw;
  } 
  .work-filter-view{
    display: none;
    
  }
  
  .work-filter-view button{
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    border-radius: 50%;
   
    margin-right: 1rem;
    height: 4rem;
    width: 4rem ;
  }
  .container-mvp {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 2rem;
    width: 100%;
  }
  .card {
    border-bottom: 1px solid rgb(226, 226, 226);
    padding: 0px;
  }

  .card-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .projects-title, 
  .projects-location, 
  .projects-services {
    width: 100%;
    font-size: 4vw;
    margin-bottom: 0.5rem;
  }

  .projects-location{
    display: none;
  }
  .servi-year-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
   
  }
  .projects-year{
    width: 20%;
  }
}


