
/*Mobile*/
.landing-headshot-image{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: -1;
  height: 90vh;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;

}
.landing-container {
  opacity: 0; /* Initially hide the landing content */
  height: 90vh;
  background-color: var(--hero-background-color);
  z-index: 2;
  width: 100%;
  overflow-x: hidden;
  transition: opacity 8s; /* Optional: add a transition for a smoother effect */
}
.wrapper {
  height: 290px;
  max-width: 100vw;;
  position: absolute;
  top:40%;
  display: flex;
  align-items: center;
  overflow: hidden;
 
}
.carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.boxtest{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 9vh;
  position: relative;
  flex-shrink: 0;
  color: var(--secondary-color);
  font-size: 20vh;
  font-family: "Helvetica";
  overflow-x:hidden;
}
.label-text{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: 58vh; 
  left: 2rem;
  width: 100%;
  font-size: 24px;
  color: var(--secondary-color);
}
.flecha-icon{
  margin-bottom: 2.5rem;
}
.container-globo{
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;


}
.globe {
  padding-right: 4rem;
  width: 100px;
  height: 50%;
}

.globo-text-container{
  display: none;
}
@keyframes rotate {
  from {
      transform: rotateY(0deg);
  }
  to {
      transform: rotateY(360deg);
  }
}
.wireframe {
  stroke: var(--secondary-color);
  fill: none;
  stroke-width: .5;
}


/*Desktop*/

@media (width >= 800px) {
  .landing-headshot-image{

    height: 100vh;
  
    bottom: 0;
  
  }
  .landing-container {
    height: 100vh;
  
  }
  .container-globo{
    background-color: var(--primary-color);
    width: 17vw;
    height: 7vw;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  
  }
  .globe {
    background-color: var(--hero-background-color);
    padding: 1rem;
    border-radius: 100%;
    margin-right: 0rem;

    width: 5vw;
    height: 5vw;
  }
  .globo-text-container{
    display: flex;
    flex-direction: column;
  }
  .container-globo-text{
    font-size: 1vw;
    color: var(--secondary-color)
  }
  .label-text{

    top: 35vh; 
    flex-direction: row-reverse;
    width: 90%;
    left: 0rem;

  }
  .landing-profession-text{
    font-size: 2vw;
  }
  .wrapper {
  
    top: 65%;

   
  }
  .boxtest{

    font-size: 25vh;
 
  }
}