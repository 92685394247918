.lab-container-of-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.lab-container {
    display: flex;
    height: 70vh
  }
  
  .lab-left-panel {
    width: 30vw;
    background-color: #f0f0f0;
    padding: 20px;

  }
  
  .lab-project-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .lab-project-item {
    padding: 10px;
    cursor: pointer;
    background-color: #e0e0e0;
    border-radius: 4px;
    flex: 0 0 auto; 
    min-width: 120px; 
    white-space: nowrap; 
  }
  
  .lab-project-item:hover {
    background-color: #d0d0d0;
  }

  .lab-project-item.active {
    background-color:#3d3de7;
    color: white;
  }
  
  .lab-right-box {
    width: calc(70vw - 2rem);
    height: calc(100% - 0rem);
    background-color: #ffffff;
    border-left: 1px solid grey;
  }
  .lab-project-content h2{
    position: absolute;
    padding: 1rem;   

 
    color: #ffffff;
  }
  /* Responsive Design */
  @media (max-width: 600px) {
    .lab-container ::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .lab-container {
      flex-direction: column-reverse;
      height: calc(100vh - 6rem);
    }

    .right-box-container{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .lab-left-panel, .lab-right-box {
      width: 100vw;

    }
  
    .lab-right-box {
      border-left: none;
      border-top: 1px solid #ccc;
      height: calc(100vh - 14rem);
      width: 90vw;
    }
  
    .lab-project-list {
      display: flex;
      flex-direction: row;
      gap: 5px;
      overflow-x: auto;
      white-space: nowrap;
    }
  
    .lab-project-item {
      display: inline-block;
      padding: 8px;
      margin-bottom: 0;
      flex: 0 0 auto; /* Prevent flex items from growing or shrinking */
      min-width: 120px; /* Ensure items have a minimum width */
      white-space: nowrap; /* Prevent text wrapping */
    }
  }
  