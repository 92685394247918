  .contact-main-container{
    opacity: 0;
    transition: opacity 3s;
    width: 100%;
  }
  .contact-container{
      padding: 4rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .flecha-icon-contact{
      position: relative;
      left: 90%;
      bottom: 0vw;
      
      transform: rotate(90deg);
    
  }
  .contact-container-title{
      padding-top: 8rem;
      padding-bottom: 3rem;
      width: 100%;
  }
  .contact-img-title-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      
  }
  .contact-container-title h2{
    font-size: 9vw;
    line-height: 7.5vw;
  }
  .contact-img{
      height: 8vw;
      width: 8vw;
      border-radius: 100%;
      margin-right: 1.6vw;
  }
  .contact-form-details-container{
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-top: 15vw;
  }
  .contact-container-form{
      width: 70%;
      height: auto;
  }
  .contact-minimal-form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
  }
  .contact-form-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid grey;
      padding: 2rem;
      padding-bottom: 3rem;
    
  }
  .contact-form-group:first-child {
      border-top: 1px solid grey;
      padding-top: 3.2rem;
    
  }
  label {
      margin-bottom: 0.5rem;
      font-weight: bold;
  }
  input{
    background-color: #f5f5f5;
  }
  #message{
    background-color: #f5f5f5;

  }
  input, textarea {
    padding: 0.5rem;
    padding-left: 0;
    border-radius: 4px;
    font-size: 1rem;
    outline: none; /* Removes default focus outline */

  } 
  textarea {
    resize: vertical;
  }
  .contact-submit-button {
    height: 30vw;
    width: 30vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    border-radius: 100%;
    background-color: var(--tertiary-color);
    color: white;
    font-size: 1rem;
    cursor: pointer;

    position: relative;
    top: -100px;
    left: 60%;
  }
  .contact-submit-button:hover {
    background-color: var(--tertiary-color-half-opacity);
  } 
  .contact-container-details{
      width: 30%;
      height: auto;
  }
  .contact-detail-container{
      margin-bottom: 3vw;
      display: flex;
      flex-direction: column;
  }
  .contact-detail-container a:hover{
    color: var( --primary-color-half-opacity);
  }
  .detail-title{
      font-size: 0.6vw;
  }
  .detail-content{
      font-size: 1vw;
  }


@media ( width <= 800px ){
    .contact-container{
        padding: 2rem;
        width: 100%;
    }
    .contact-form-details-container{
    
        flex-direction: column-reverse;
        padding-top: 3.5vw;
      
    }
    .contact-container-details{
        width: 100%;
     
    }
    .contact-container-form{
        width: 100%;
     
    }
    .contact-detail-container{
        margin-bottom: 12vw;
    }
    .contact-detail-container:last-child{
        display: none;
    }
    .detail-title{
        font-size: 2vw;
    }
    .detail-content{
        margin-top: 1vw;
        font-size: 3vw;
    }
   
}
@media ( width >= 800px ){
    .contact-container{
        padding: 15vw;
        padding-top: 2rem;
        width: 100%;
    }
    .contact-form-details-container{
        padding-top: 10vw;
    }
    .contact-container-title h2{
        font-size: 6vw;
    }
    .contact-form-group{
        margin-right: 6vw;
    }
    .contact-img{
        display: none;
    }
    .flecha-icon{
      display: none;
       
    }
    .contact-submit-button {
        height: 12vw;
        width: 12vw;
    }
    label{
      font-size: 1.4vw;
      font-weight: 300;
    }
    input{
      font-size: 1.2vw;
    }
   
}