html {
  font-size: 16px; /* Adjust to your preference */
  font-family: 'Roboto', sans-serif; /* Change to your preferred font */
  background-color:#f5f5f5;
  overflow-x: hidden;

}

:root {
  --spacing-xxs: 0.25rem; /* 4px */
  --spacing-xs: 0.5rem; /* 8px */
  --spacing-sm: 1rem; /* 16px */
  --spacing-md: 1.5rem; /* 24px */
  --spacing-lg: 2rem; /* 32px */
  --spacing-xl: 3rem; /* 48px */
  --spacing-xxl: 4rem; /* 64px */
  --spacing-xxxl: 7rem; /* 128px */

  --primary-color: #1C1C1C;
  --primary-color-half-opacity: #1c1c1cad;
  --secondary-color: #f5f5f5;
  --secondary-color-half-opacity: #f5f5f5d2;
  --tertiary-color: #3d3de7;
  --tertiary-color-half-opacity: #3d3de7f7;
  --hero-background-color: #868686;
  

  --width-sm:1200px;


}
.app-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}