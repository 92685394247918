.footer-container-mini{
  width: 100%;
  background-color: var(--secondary-color);
  padding: 2rem;
  padding-top:0;
 }

.footerMiniMini-container{
   width: 100%;
}

.footer-version-time-container-mini {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-top: 3rem;
   padding-top: 3rem;
   border-top: 1px solid;
   width: 100%;
  }

  .footer-title-end-mini{
   color:var(--secondary-color);   
   font-size: 12px;
}

