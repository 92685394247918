.success-container{
    width: 100%;
    height: 76.12vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.success-text-container{
    border-bottom: 1px solid rgb(29, 29, 23);
    width: 70%;
    height: 45%;
    margin-top: 10vw;

}

.success-secondary-text-container{
    width: fit-content;
    position: relative;
    left: 75%;
}

.success-main-text{
    font-size: 6.4vw;
    line-height: 6vw;
}

.success-secondary-text{
    font-size: 1vw;
}

.success-back-to-contact{
    min-height: 10vw;
    min-width: 10vw;

    border-radius: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--tertiary-color);
    color: var(--secondary-color);
    font-size: 1vw;

    position: relative;
    top: -5vw;
    left: 21vw;

    cursor: pointer ;
}

@media (width <= 800px )  {
    .success-container{
        width: 100%;
        height: 56.8vh;
    }
    .success-text-container{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        width: 90%;
    }
    .success-secondary-text-container{
        width: fit-content;
        position:inherit;
        padding-top: 3vw;
        padding-bottom: 3vw;
 
    }
    .success-main-text{
        font-size: 7vw;
        line-height: 6vw;
    }
    .success-secondary-text{
        font-size: 2vw;
    }

    .success-back-to-contact{
        height: 20vw;
        width: 20vw;
        font-size: 2vw;
        top: -10vw;
        left: 20vw;
    }


}